<!--
 * @Descripttion: 商品详情
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-08-14 14:18:15
-->

<template>
  <div id="details" v-if="productDetails" @click="goCartVisible = false">
    <!-- 头部 -->
    <!-- <div class="page-header">
      <div class="title">
        <p>{{ productDetails.title }}</p>
        <div class="list">
          <div class="select">
            <el-button type="text" class="list-select" @click="goInfo">
              概述
            </el-button>
          </div>
         <span class="cut">|</span>
          <div class="select">
            <el-button type="text" class="list-select" @click="goParam">
              参数
            </el-button>
          </div>
          <span class="cut">|</span>
          <div class="select">
            <el-button type="text" class="list-select">用户评价</el-button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 头部END -->

    <!-- 主要内容 -->
    <div class="details-main clearfix">
      <!-- 左侧商品轮播图 -->
      <div class="details-block">
        <el-carousel height="560px" v-if="productPictures.length > 1">
          <el-carousel-item v-for="item in productPictures" :key="item.id">
            <img style="height: 560px;" v-lazy="item.path" />
          </el-carousel-item>
        </el-carousel>
        <div v-if="productPictures.length == 1">
          <img style="height: 560px;" :src="productPictures[0].path" />
        </div>
      </div>
      <!-- 左侧商品轮播图END -->

      <!-- 右侧内容区 -->
      <div class="details-content">
        <h1 class="name">{{ title }}</h1>
        <!-- <li class="view">
          <i class="el-icon-view"></i>
			1000
        </li> -->
        <p class="intro">{{ remark }}</p>
        <!-- <p class="store">尚明自营</p> -->
        <div class="price">
          <span>{{ price | filterCurrency }}元</span>
          <!-- <span
            v-show="productDetails.price != productDetails.discount_price"
            class="del"
          >
            {{ productDetails.price }}元
          </span> -->
        </div>
		<div class="buy-box-child" v-if="productDetails.attrs">
			<div v-for="(attr,akey) in productDetails.attrs" :key="akey" class="option-box">
				<div class="title">{{ attr.label }}</div>
				<ul class="clearfix">
					<li v-for="(at, atkey) in attr.attrs" :key="atkey" :class="at.active ? 'active' : ''" @click="selectAttr(akey, atkey)"><a>{{ at.title }}</a></li>
				</ul>
			</div>
		</div>
        <!-- <div class="pro-list">
          <span class="pro-name">{{ productDetails.title }}</span>
          <span class="pro-price">
            <span>{{ productDetails.price | filterCurrency }}元</span>
            <span
              v-show="productDetails.price != productDetails.discount_price"
              class="pro-del"
            >
              {{ productDetails.price }}元
            </span>
          </span>
          <p class="price-sum">总计 : {{ productDetails.price | filterCurrency }}元</p>
        </div> -->
        <!-- 内容区底部按钮 -->
        <div class="button">
			<el-popover
				placement="bottom"
				title="加入购物车成功"
				width="300"
				trigger="manual"
				v-model="goCartVisible">
				<p>是否进入购物车结算？</p>
				<div style="text-align: center; margin: 10px 0 0">
					<div class="myButton" @click="goCartVisible = false">取 消</div>
					<div class="myButton orange" @click="goCart">去购物车</div>
				</div>
				<el-button slot="reference" class="shop-cart" :disabled="dis" @click.stop="addShoppingCart" :loading="addCartLoading">加入购物车</el-button>
			</el-popover>
          <!-- <el-button class="shop-cart" :disabled="dis" @click="addShoppingCart">加入购物车</el-button>
          <el-button class="like" @click="addFavorite">喜欢</el-button> -->
        </div>
        <!-- 内容区底部按钮END -->
        <!-- <div class="pro-policy">
          <ul>
            <li>
              <i class="el-icon-circle-check"></i>
              尚明自营
            </li>
            <li>
              <i class="el-icon-circle-check"></i>
              小米发货
            </li>
            <li>
              <i class="el-icon-circle-check"></i>
              7天无理由退货
            </li>
            <li>
              <i class="el-icon-circle-check"></i>
              7天价格保护
            </li>
          </ul>
        </div> -->
      </div>
      <!-- 右侧内容区END -->
    </div>
    <!-- 主要内容END -->
    <!-- <div class="product-select" id="product-select">
      <el-button
        type="text"
        :class="select == 0 ? 'isSelect' : 'notSelect'"
        @click="showInfoImgs"
      >
        商品概述
      </el-button>
      <span class="cut">|</span>
      <el-button
        type="text"
        :class="select == 1 ? 'isSelect' : 'notSelect'"
        @click="showParamImgs"
      >
        商品参数
      </el-button>
    </div> -->
	<div id="my-markdown" class="markdown-body">
		<vue-markdown :source="productDetails.content.content"></vue-markdown>
	</div>
    <!-- <div class="product-img" v-for="item in imgs" :key="item.id">
      <img v-lazy="item.img_path" />
    </div> -->
  </div>
  <div class="not-found" v-else>
	<div v-if="loading" style="font-size: 32px;text-align: center;color: #666;">
		<i class="el-icon-loading"></i>
	</div>
	<div v-else class="none-product">
		抱歉，没有找到该品
	</div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as productsAPI from '@/api/products/'
// import * as imgsAPI from '@/api/img/'
import * as favoritesAPI from '@/api/favorites/'
import * as cartsAPI from '@/api/carts/'
import { filterCurrency } from '@/filters/index.js'
import VueMarkdown from 'vue-markdown'
export default {
  data() {
    return {
      dis: false, // 控制“加入购物车按钮是否可用”
      productID: 0, // 商品id
      productDetails: '', // 商品详细信息
      productPictures: [], // 商品图片
      imgs: [], //商品概述图片
      infoImgs: '',
      paramImgs: '',
      select: 0,
		price: 0,
		title: '',
		remark: '',
		selectedAttrIds: [] ,// 选中的属性ids
		selectedSpecId: 0, // 选中的规格id
		selectKey: -1, // 选中的规格的下标
		loading: true,
		goCartVisible: false,
		addCartLoading: false
    }
  },
  filters: {
    filterCurrency
  },
  components: {
    VueMarkdown
  },
  computed: {
    ...mapGetters(['getShoppingCart'])
  },
  // 通过路由获取商品id
  activated() {
    if (this.$route.query.productID != undefined) {
      this.productID = this.$route.query.productID
    }
  },
  watch: {
    // 监听商品id的变化，请求后端获取商品数据
    productID: function () {
      this.load()
      //this.getDetailsPicture(val);
    },
  },
  methods: {
    ...mapActions(['unshiftShoppingCart', 'addShoppingCartNum', 'updateShoppingCart']),
	goCart() {
		this.$router.push({ name: 'Cart' })
	},
	selectAttr(k1, k2) {
		this.productDetails.attrs[k1].attrs.forEach(function(attr){
			attr['active'] = 0
		})
		this.productDetails.attrs[k1].attrs[k2]['active'] = 1
		this.selectedAttrIds[k1] = this.productDetails.attrs[k1].attrs[k2].id
		let selectKey = -1
		this.productDetails.specs.forEach(function(spec, sind){
			let flag = 1
			this.forEach(function(attrid){
				if(!spec.spec_attrid.includes(''+attrid)){
					flag = 0
				}
			})
			if (flag) {
				selectKey = sind
			}
		}, this.selectedAttrIds)
		if (selectKey > -1) {
			this.price = this.productDetails.specs[selectKey].price
			this.title = this.productDetails.specs[selectKey].title ?? this.productDetails.title
			this.remark = this.productDetails.specs[selectKey].remark ?? this.productDetails.remark
			this.productPictures = this.productDetails.specs[selectKey].gallery_img ?? this.productDetails.gallery_img
			this.selectedSpecId = this.productDetails.specs[selectKey].id
			this.selectKey = selectKey
		}
	},
    // 获取商品详细信息
    load() {
		this.loading = true
      productsAPI.showProduct(this.productID).then((res) => {
		this.loading = false
		this.productPictures = res.data.gallery_img
		this.price = res.data.price
		this.title = res.data.title
		this.remark = res.data.remark
		res.data.attrs.forEach(function(attr){
			attr.attrs.forEach(function(at, ind){
				if (ind === 0) { // 默认选中第一个属性值
					at['active'] = 1
					this.push(at.id)
				} else {
					at['active'] = 0
				}
			}, this)
		}, this.selectedAttrIds)
		this.productDetails = res.data
		let selectKey = -1
		this.productDetails.specs.forEach(function(spec, sind){
			let flag = 1
			this.forEach(function(attrid){
				if(!spec.spec_attrid.includes(''+attrid)){
					flag = 0
				}
			})
			if (flag) {
				selectKey = sind
			}
		}, this.selectedAttrIds)
		if (selectKey > -1) {
			this.price = this.productDetails.specs[selectKey].price
			this.title = this.productDetails.specs[selectKey].title ?? this.productDetails.title
			this.remark = this.productDetails.specs[selectKey].remark ?? this.productDetails.remark
			this.productPictures = this.productDetails.specs[selectKey].gallery_img ?? this.productDetails.gallery_img
			this.selectedSpecId = this.productDetails.specs[selectKey].id
			this.selectKey = selectKey
		}
      })
      // productsAPI.showPictures(this.productID).then((res) => {
      //   this.productPictures = res.data
      // })
      // imgsAPI.showInfoImgs(this.productID).then((res) => {
      //   this.infoImgs = res.data
      //   this.imgs = this.infoImgs
      // })
      // imgsAPI.showParamImgs(this.productID).then((res) => {
      //   this.paramImgs = res.data
      // })
    },
    goInfo() {
      this.showInfoImgs()
      document.getElementById('product-select').scrollIntoView()
    },
    goParam() {
      this.showParamImgs()
      document.getElementById('product-select').scrollIntoView()
    },
    showInfoImgs() {
      this.select = 0
      this.imgs = this.infoImgs
    },
    showParamImgs() {
      this.select = 1
      this.imgs = this.paramImgs
    },
    // 加入购物车
    addShoppingCart() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$router.push({ name: 'Login' })
        return
      }
      var form = {
        // uid: this.$store.getters.getUser.id,
        product_id: Number(this.productID),
		product_item_id: this.selectedSpecId,
		quantity: 1
      }
		this.addCartLoading = true
      cartsAPI
        .postCart(form)
        .then((res) => {
			this.addCartLoading = false
          switch (res.status_code) {
			case 200:
				//新加入购物车成功
				res.data.product = { id: this.productID, price: this.productDetails.price, title: this.productDetails.title, cover_img: this.productDetails.cover_img }
				if (this.selectKey > -1) {
					res.data.specs = this.productDetails.specs[this.selectKey]
				} else {
					res.data.specs = null
				}
				var ind = -1
				this.getShoppingCart.forEach((item, index) => {
					if(item.id === res.data.id) {
						ind = index
					}
				})
				if (ind > -1) { // 购物车已经存在则更新
					this.updateShoppingCart({
						key: ind,
						prop: 'quantity',
						val: res.data.quantity
					})
				} else { // 不存在则添加在前面
					res.data.check = false
					this.unshiftShoppingCart(res.data)
				}
				// this.notifySucceed('添加购物车成功')
				this.goCartVisible = true
				break
            // case 201:
            //   // 该商品已经在购物车，数量+1
            //   this.addShoppingCartNum(this.productID)
            //   this.notifySucceed('该商品已在购物车，数量+1')
            //   break
            // case 202:
            //   // 商品数量达到限购数量
            //   this.dis = true
            //   this.notifyError('商品达到限购数量', res.msg)
            //   break
            // case 20001:
            //   //token过期，需要重新登录
            //   this.loginExpired(res.msg)
            //   break
			default:
				this.notifyError('添加购物车失败', res.msg)
          }
        })
        .catch((err) => {
			this.addCartLoading = false
          // this.notifyError('添加购物车失败', err)
        })
    },
    addFavorite() {
      // 判断是否登录,没有登录则显示登录组件
      if (!this.$store.getters.getUser) {
        this.$router.push({ name: 'Login' })
        return
      }
      var form = {
        user_id: this.$store.getters.getUser.id,
        product_id: Number(this.productID),
      }
      favoritesAPI
        .postFavorite(form)
        .then((res) => {
          if (res.status === 200) {
            this.notifySucceed('添加收藏夹成功')
          } else if (res.status === 20001) {
            //token过期，需要重新登录
            this.loginExpired(res.msg)
          } else {
            this.notifyError('添加收藏夹失败', res.msg)
          }
        })
        .catch((err) => {
          this.notifyError('添加收藏夹失败', err)
        })
    },
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#ffffff')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#f5f5f5')
    next()
  },
}
</script>
<style scoped>
/* 头部CSS */
#my-markdown{
	width: 1225px;
	margin: 0 auto;
	/* padding: 20px 0; */
}
.clearfix:after {
	clear: both;
	display: block;
	content: '';
}
#details .page-header {
  height: 64px;
  z-index: 4;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
}
#details .page-header .title {
  width: 1225px;
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  font-weight: 400;
  color: #212121;
  margin: 0 auto;
}
#details .page-header .title p {
  float: left;
}
#details .page-header .title .list {
  height: 64px;
  float: right;
  display: flex;
  align-items: center;
}
#details .page-header .title .list .select {
  float: left;
  margin-left: 20px;
}

#details .page-header .title .list .select .list-select {
  font-size: 14px;
  color: #616161;
}
#details .page-header .title .list .select .list-select:hover {
  font-size: 14px;
  color: #ff6700;
}
#details .page-header .title .list .cut {
  font-size: 17px;
  color: #c9c7c7;
  margin-left: 20px;
}
.markdown-body ::v-deep img{
	width: 100%;
}
/* 头部CSS END */

/* 主要内容CSS */
#details .details-main {
  width: 1225px;
  /* height: 560px; */
  padding-top: 30px;
  margin: 0 auto;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
#details .details-main .details-block {
  float: left;
  width: 560px;
  height: 560px;
}
#details .el-carousel .el-carousel__indicator .el-carousel__button {
  background-color: rgba(163, 163, 163, 0.8);
}
#details .details-main .details-content {
  float: left;
  margin-left: 25px;
  width: 640px;
}

#details .details-main .details-content .name {
  line-height: 30px;
  font-size: 24px;
  font-weight: normal;
  color: #212121;
  display: inline;
}

#details .details-main .details-content .view {
  color: #b0b0b0;
  float: right;
  padding-top: 5px;
  font-size: 18px;
}

#details .details-main .details-content .intro {
  color: #b0b0b0;
  padding-top: 10px;
}
#details .details-main .details-content .store {
  color: #ff6700;
  padding-top: 10px;
}
#details .details-main .details-content .price {
  display: block;
  font-size: 18px;
  color: #ff6700;
  border-bottom: 1px solid #e0e0e0;
  padding: 25px 0 25px;
}
#details .details-main .details-content .price .del {
  font-size: 14px;
  margin-left: 10px;
  color: #b0b0b0;
  text-decoration: line-through;
}
.buy-box-child {
	margin-top: 30px;
	margin-bottom: 30px;
	position: relative;
}
#details .details-main .details-content .option-box {
	margin-bottom: 30px;
	position: relative;
}
#details .details-main .details-content .option-box:last-child {
	margin-bottom: 0px;
}
#details .details-main .details-content .option-box .title{
	font-size: 18px;
	color: #333;
}
.option-box li {
	margin-right: 12px;
	margin-top: 10px;
	line-height: 42px;
	height: 42px;
	position: relative;
	border: 1px solid #e0e0e0;
	list-style: none;
	cursor: pointer;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	float: left;
	width: 312px;
	font-size: 16px;
	text-align: center;
}
.option-box li:nth-child(2n) {
    margin-right: 0;
}
.option-box li.active {
	z-index: 3;
	border-color: #ff6700;
}
.myButton {
	border-radius: 3px;
	font-size: 12px;
	padding: 7px 15px;
	display: inline-block;
	border: 1px solid #aaa;
	margin-left: 20px;
	cursor: pointer;
}
.myButton.orange{
	color: #ff6700;
	border-color: #ff6700;
}
#details .details-main .details-content .pro-list {
  background: #f9f9f9;
  padding: 30px 60px;
  margin: 50px 0 50px;
}
#details .details-main .details-content .pro-list span {
  line-height: 30px;
  color: #616161;
}
#details .details-main .details-content .pro-list .pro-price {
  float: right;
}
#details .details-main .details-content .pro-list .pro-price .pro-del {
  margin-left: 10px;
  text-decoration: line-through;
}
#details .details-main .details-content .pro-list .price-sum {
  color: #ff6700;
  font-size: 24px;
  padding-top: 20px;
}
#details .details-main .details-content .button {
  height: 55px;
  margin: 10px 0 20px 0;
}
#details .details-main .details-content .button .el-button {
  float: left;
  height: 55px;
  font-size: 16px;
  color: #fff;
  border: none;
  text-align: center;
}
#details .details-main .details-content .button .shop-cart {
  width: 340px;
  background-color: #ff6700;
}
#details .details-main .details-content .button .shop-cart:hover {
  background-color: #f25807;
}

#details .details-main .details-content .button .like {
  width: 260px;
  margin-left: 40px;
  background-color: #b0b0b0;
}
#details .details-main .details-content .button .like:hover {
  background-color: #757575;
}
#details .details-main .details-content .pro-policy li {
  float: left;
  margin-right: 20px;
  color: #b0b0b0;
}
/* 主要内容CSS END */

/*商品概述&参数*/
#details .product-select {
  width: 300px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#details .product-select .notSelect {
  font-size: 19px;
  color: #757575;
}
#details .product-select .isSelect {
  font-size: 19px;
  color: #ff6700;
}
#details .product-select .notSelect:hover {
  color: #ff6700;
}
#details .product-select .cut {
  font-size: 25px;
  color: #c9c7c7;
  margin-left: 35px;
  margin-right: 35px;
}
#details .product-img img {
  width: 1225px;
  display: block;
  margin: 0 auto;
}
/*商品概述&参数END*/

/*v-else*/
.not-found {
  height: 400px;
  text-align: center;
  padding-top: 100px;
}
/*v-else END*/
</style>
